import { AppProps, RegisterApplicationConfig } from 'single-spa';

const app: RegisterApplicationConfig = {
  name: '@sx/mf-advisergw-dev-sitecore-html',
  activeWhen: ({ pathname }: Pick<Location, 'pathname'>) => !!pathname.match(/^\/dev-sitecore-html\/?$/),
  app: ({ name }: AppProps) => {
    return System.import(name);
  },
  customProps: {
    mountElementId: 'content-body',
  },
};

export default app;
