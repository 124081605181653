import { AppProps, RegisterApplicationConfig } from 'single-spa';
import mediaQueries from '@sx/advisergw-runtime-utils/media-queries.scss';
import routes from '../routes.ts';

const app: RegisterApplicationConfig = {
  name: '@sx/mf-advisergw-nav-panel',
  activeWhen: (location) =>
    !!location.pathname.match(/^\/dev-sitecore-html\/?$/) ||
    Object.values(routes)
      .filter((route) => ![routes.auth, routes.migrateMe].includes(route))
      .some(({ activeWhen }) => activeWhen(location)),
  app: ({ name }: AppProps) => {
    return System.import(name);
  },
  customProps: {
    config: [
      {
        mediaQuery: mediaQueries['desktop-any'],
        appearance: 'desktop',
        mountElementId: 'nav-panel__aside',
      },
      {
        mediaQuery: mediaQueries['tablet-any'],
        appearance: 'tablet',
        mountElementId: 'nav-panel__top',
      },
      {
        mediaQuery: mediaQueries['mobile-any'],
        appearance: 'mobile',
        mountElementId: 'nav-panel__top',
      },
    ],
  },
};

export default app;
